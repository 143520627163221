import React from "react"

import SearchPageContent from "../components/search/search-page-content"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"

import { getQueryVariable } from "../lib/utils"

const SearchPage = ({ location }) => {
  const query = getQueryVariable("query")

  const title = "Search"

  return (
    <Layout location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <h1>Search Results</h1>
      <SearchPageContent query={query} />
    </Layout>
  )
}

export default SearchPage
